import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PoolsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PoolsQuery = { __typename?: 'Query', pools: Array<{ __typename?: 'LiquidityPoolContract', id: string, address: string, chainId: number, token: string, tokenDecimals: number, asset: string, tvl: string, apr: string, coreAddresses?: Array<string> | null, withdrawTimeout: string, liquidityManager?: string | null, createdAt: string }> };


export const PoolsDocument = gql`
    query Pools {
  pools: liquidityPoolContracts(
    subgraphError: allow
    orderBy: asset
    orderDirection: desc
  ) {
    id
    address
    chainId
    token
    tokenDecimals
    asset
    tvl
    apr
    coreAddresses
    withdrawTimeout
    createdAt: firstCalculatedBlockTimestamp
    liquidityManager
  }
}
    `;

/**
 * __usePoolsQuery__
 *
 * To run a query within a React component, call `usePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoolsQuery(baseOptions?: Apollo.QueryHookOptions<PoolsQuery, PoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PoolsQuery, PoolsQueryVariables>(PoolsDocument, options);
      }
export function usePoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoolsQuery, PoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PoolsQuery, PoolsQueryVariables>(PoolsDocument, options);
        }
export function usePoolsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PoolsQuery, PoolsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PoolsQuery, PoolsQueryVariables>(PoolsDocument, options);
        }
export type PoolsQueryHookResult = ReturnType<typeof usePoolsQuery>;
export type PoolsLazyQueryHookResult = ReturnType<typeof usePoolsLazyQuery>;
export type PoolsSuspenseQueryHookResult = ReturnType<typeof usePoolsSuspenseQuery>;
export type PoolsQueryResult = Apollo.QueryResult<PoolsQuery, PoolsQueryVariables>;